import Box from '@mui/joy/Box'
import Stack from '@mui/joy/Stack'

interface GalleryItemProps {
    item: {
        id: string;
        image: string;
    }
    onClick: (image: string) => void
}

export default function GalleryItem({ item, onClick }: GalleryItemProps) {

  return (
    <Stack
      onClick={() => onClick(item.image)}
      style={{
        cursor: 'pointer',
        float: 'left',
        padding: '8px'
      }}
      width={{
        xs: '100%',
        sm: '50%'
      }}>
      <div
        style={{
          aspectRatio: 1,
          backgroundImage: `url(${item.image})`,
          content: '""',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      />

    </Stack>
  )
}
