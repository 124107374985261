import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/joy/Box'

import Footer from 'components/footer'
import Header from 'components/header'

export default function RootPage() {

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Header />
      <Outlet />
      <Box flexGrow={1} />
      <Footer />
    </div>
  )
}
