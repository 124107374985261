import React from 'react'
import Container from '@mui/joy/Container'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'
import { Brain, BrickWall, Headset, Map } from 'lucide-react'

import FacebookIcon from '../assets/facebook.icon'
import InstagramIcon from '../assets/instagram.icon'

import HowWeWorkItem from './how-we-work.item'

const StyledHeading = styled(Typography)({
  color: '#d5bda2',
  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '0px',
  '@media screen and (min-width:1025px)': {
    fontSize: '5em'
  }
})

const StyledLink = styled(Link)({
  color: '#D5BDA2',
  '&>svg': {
    fill: '#D5BDA2'
  }
})

export default function HowWeWork() {

  const items = [{
    order: '01',
    icon: <Headset
      height={50}
      strokeWidth={1}
      width={50} />,
    title: 'Contact Us',
    text: <Stack
      alignItems={'center'}
      justifyItems={'center'}
      lineHeight={0.5}
    >
      <StyledLink href={'mailto:alen@alwoodflooring.ca'}>
        alen@alwoodflooring.ca
      </StyledLink>
      <br/>
      <StyledLink href={'tel:+14166979771'}>
        +1 416 6979771
      </StyledLink>
      <br/>
      <Stack
        direction={'row'}
        gap={3}
        justifyContent={'center'}
      >
        <StyledLink
          href={'https://www.facebook.com/people/Alwood-Flooring/61552465298641/'}
          target={'_blank'}>
          <FacebookIcon/>
        </StyledLink>
        <StyledLink
          href={'https://www.instagram.com/alwoodflooring'}
          target={'_blank'}>
          <InstagramIcon/>
        </StyledLink>
      </Stack>
    </Stack>
  }, {
    order: '02',
    icon: <Brain
      height={50}
      strokeWidth={1}
      width={50} />,
    title: 'Consultation',
    text: 'Personalized and Free Estimate.'
  }, {
    order: '03',
    icon: <Map
      height={50}
      strokeWidth={1}
      width={50} />,
    title: 'Location Survey',
    text: 'Detail location to ensure the perfect flooring for your space.'
  }, {
    order: '04',
    icon: <BrickWall
      height={50}
      strokeWidth={1}
      width={50} />,
    title: 'Processing',
    text: 'Smooth and timely installation process, with careful attention to every detail from start to finish.'
  }]

  return (

    <Stack
      py={{
        xs: 3,
        md: 12
      }}
      sx={{
        backgroundColor: '#3c352e',
        width: '100%',
        zIndex: 1,
        top: '0px'
      }}
    >
      <Container
        maxWidth={'lg'}
      >
        <Stack
          alignContent={'space-evenly'}
          alignItems={'center'}
          gap={3}
          width={'100%'}
          direction={{
            xs: 'column',
            md: 'row'
          }}>
          <Stack
            alignItems={{
              xs: 'center',
              md: 'flex-start'
            }}
            width={{
              xs: '80%',
              md: '50%'
            }}>
            <StyledHeading
              level={'h3'}>
              HOW WE WORK
            </StyledHeading>
          </Stack>
          <Stack
            width={{
              xs: '80%',
              md: '50%'
            }}
          >
            <Typography
              sx={{
                color: '#bababa'
              }}
              textAlign={{
                xs: 'center',
                sm: 'left'
              }}>
              At Alwood Flooring, our approach to flooring is rooted in professionalism, efficiency,
              and a commitment to excellence. We understand that every project is unique, which is why
              we tailor our process to meet your specific needs and preferences.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          gap={3}
          direction={{
            xs: 'column',
            md: 'row'
          }}
          pt={{
            xs: 3,
            md: 5
          }}>
          {items.map((item) => (
            <HowWeWorkItem
              key={item.order}
              {...item}
            />
          ))}
        </Stack>
      </Container>
    </Stack>
  )
}
