import React from 'react'
import { Avatar } from '@mui/joy'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { Quote } from 'lucide-react'

interface OurTestimonialsItemClientProps {
    avatar: string
    name: string
    role: string
    isHighlighted?: boolean
}
export default function OurTestimonialsItemClient({ avatar, name, role, isHighlighted }: OurTestimonialsItemClientProps) {

  return (
    <Stack
      direction={'row'}
      gap={3}>
      <Stack
      >
        <Avatar
          alt={name}
          src={avatar}
          sx={{
            '@media screen and (min-width:599px)': {
              width: '70px',
              height: '70px'
            }
          }} />
      </Stack>
      <Stack
        direction={'row'}
        flex={1}
        justifyContent={'space-between'}
      >
        <Stack
          justifyContent={'center'}
        >
          <Typography
            level={'title-lg'}
            sx={{
              color: isHighlighted? '#fff': 'initial'
            }}>
            {name}
          </Typography>
          <Typography
            level={'body-sm'}
            sx={{
              color: isHighlighted? '#bababa': 'initial'
            }}>
            {role}
          </Typography>
        </Stack>
        <Stack justifyContent={'center'}>
          <Quote
            height={40}
            stroke={isHighlighted? '#d5bda2': 'currentcolor'}
            width={40}/>
        </Stack>
      </Stack>
    </Stack>
  )
}
