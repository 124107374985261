import React from 'react'
import Container from '@mui/joy/Container'
import Divider from '@mui/joy/Divider'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

import AboutUsItem from './about-us-item'

const StyledOutBorder = styled(Stack)({
  position: 'absolute',
  borderLeft: '2px solid rgb(122, 122, 122)',
  borderBottom: '2px solid rgb(122, 122, 122)',
  width: '90%',
  height: '90%',
  bottom: -20,
  left: -20
})

const StyledImage = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100%',
  backgroundColor: '#ede0d4',
  '&:before': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    content: '""',
    backgroundImage: 'url("https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Wood-Textures-17-1.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    opacity: 0.03
  }
})

const StyledHeading = styled(Typography)({
  color: '#27221e',

  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '0px',
  '@media screen and (min-width:1025px)': {
    fontSize: '5em'
  }
})

const StyledTitle = styled(Typography)({
  color: '#ffffffa8',
  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '0px',
  '@media screen and (min-width:1025px)': {
    fontSize: '3em'
  }
})

export default function OurTestimonials() {

  const items = [ {
    id: 3,
    count: '100+',
    title: 'Project Done'
  },{
    id: 1,
    count: 'Always',
    title: 'Happy Client'
  },{
    id: 2,
    count: 'Best',
    title: 'Team'
  }]

  return (
    <Stack
      py={{
        xs: 7,
        md: 15
      }}
      sx={{
        backgroundColor: '#3c352e',
        width: '100%',
        zIndex: 2,
        position: 'relative'
      }}>
      <StyledImage />

      <Container
        maxWidth={'lg'}
        sx={{
          zIndex: 2
        }}
      >
        <Stack
          alignItems={'center'}
          gap={10}
          width={'100%'}
          direction={{
            xs: 'column',
            md: 'row'
          }}>
          <Stack
            width={{
              xs: '100%',
              md: '40%'
            }}>
            <StyledHeading
              level={'h2'}
              pb={3}>
              ABOUT US
            </StyledHeading>
            <Stack
              p={5}
              sx={{
                position: 'relative',
                backgroundImage: 'url("https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/a756ed86-36a0-485d-9cec-8729c30d8352-2023-11-27-04-52-12-utc.jpg")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                '&:before': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  content: '""',
                  backgroundColor: '#27221e',
                  opacity: 0.75
                }
              }}>

              <StyledOutBorder
                display={{
                  xs: 'none',
                  md: 'flex'
                }} />
              <Stack
                gap={2}
                zIndex={2}>
                <StyledTitle level={'h2'}>
                    10 YEARS EXPERIENCED
                </StyledTitle>
                <Typography
                  sx={{
                    color: '#ffffffa8'
                  }}
                >
                    With years of experience in the industry, we are committed to providing top-notch service
                    and quality products to our clients. Whether it's a small renovation or a large
                    commercial project, our team of experts is here to assist you every step of the way.
                </Typography>
              </Stack>

            </Stack>

          </Stack>
          <Stack flex={1}>
            <StyledHeading
              level={'h2'}
              sx={{
                color: '#c3a995'
              }}
            >
                Expert Craftsmanship
            </StyledHeading>
            <Divider />
            <Typography
              sx={{
                paddingTop: '20px',
                paddingBottom: '20px',
                color: '#7a7a7a'
              }}>
                Maximum dedication, professional installation, and a seamless flooring experience you’ll enjoy for years.
            </Typography>
            <br/>
            <Stack
              gap={3}
              direction={{
                xs: 'column',
                sm: 'row'
              }}>
              {items.map((item, idx) => (
                <AboutUsItem
                  key={item.id}
                  {...item}
                  isHighlighted={idx === 1}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          gap={2.5}
          pt={5}
          px={{ xs: 5, md: 0 }}
          direction={{
            xs: 'column',
            md: 'row'
          }}>

        </Stack>
      </Container>

    </Stack>
  )
}
