import React from 'react'

import Banner from 'components/banner'
import HowWeWork from 'components/how-we-work'
import OurTestimonials from 'components/our-testimonials'
import QualityFlooring from 'components/quality-flooring'

export default function ServicesPage() {

  return (
    <>
      <Banner
        title={'OUR SERVICES'}
        text={'We pride ourselves on offering a comprehensive range of services to meet all your flooring needs. '
            + 'With a commitment to excellence and customer satisfaction, we strive to deliver exceptional results '
            + 'on every project, big or small.'}
      />
      <QualityFlooring />
      <HowWeWork />
      <OurTestimonials />
    </>
  )
}
