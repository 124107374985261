import React from 'react'
import Container from '@mui/joy/Container'
import Grid from '@mui/joy/Grid'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import Sheet from '@mui/joy/Sheet'
import Stack from '@mui/joy/Stack'

import Banner from 'components/banner'

import GalleryItem from './gallery-item.component'
import { GALLERY_ITEMS } from './gallery-items'

export default function GalleryPage() {

  const [openImage, setOpenImage] = React.useState('')

  return (
    <>
      <Banner
        text={''}
        title={'Gallery'}
      />
      <Container
        maxWidth={'lg'}>
        <Stack py={5}>
          <div >
            {GALLERY_ITEMS.map((item) => (
              <GalleryItem
                key={item.id}
                item={item}
                onClick={setOpenImage} />
            ))}
          </div>
        </Stack>

        {/*<Modal*/}
        {/*  onClose={() => setOpenImage('')}*/}
        {/*  open={!!openImage}>*/}
        {/*  <ModalDialog*/}
        {/*    layout={'center'}*/}
        {/*    size={'md'}*/}
        {/*    variant={'plain'}*/}
        {/*  >*/}
        {/*    <ModalClose variant={'solid'}/>*/}
        {/*    <div*/}
        {/*      style={{*/}
        {/*        height: '70vh',*/}
        {/*        overflow: 'hidden'*/}
        {/*      }}>*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          aspectRatio: 1,*/}
        {/*          backgroundImage: `url(${openImage})`,*/}
        {/*          content: '""',*/}
        {/*          backgroundRepeat: 'no-repeat',*/}
        {/*          backgroundSize: 'cover',*/}
        {/*          backgroundPosition: 'center center'*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </ModalDialog>*/}
        {/*</Modal>*/}

      </Container>
    </>
  )
}
