import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

import { OurTestimonialsItem } from './our-testimonials-item'
import OurTestimonialsItemClient from './our-testimonials-item-client'
const StyledOutBorder = styled(Stack)({
  position: 'absolute',
  borderRight: '2px solid rgb(122, 122, 122)',
  borderTop: '2px solid rgb(122, 122, 122)',
  width: '90%',
  height: '90%',
  top: -20,
  right: -20
})
export default function OurTestimonialsItemRight({ items }: {items: OurTestimonialsItem[]}) {

  return (
    <Stack
      p={{
        xs: 1,
        md: 5
      }}
      sx={{
        position: 'relative',
        border: '1px solid #695743',
        '&:before': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          content: '""',
          backgroundColor: '#d5bda2',
          opacity: 0.7
        }
      }}
      width={{
        xs: '100%',
        md: '50%'
      }}>
      <StyledOutBorder
        display={{
          xs: 'none',
          md: 'flex'
        }} />
      <Stack zIndex={2}>
        {items.map(({ id, client, text }) => (
          <Stack
            key={id}
            gap={{
              xs: 3,
              md: 5
            }}
            p={{
              xs: 1,
              md: 4
            }}>
            <Typography sx={{ color: '#27221e' }}>
              {text}
            </Typography>
            <OurTestimonialsItemClient {...client} />
          </Stack>
        ))}
      </Stack>

    </Stack>
  )
}
