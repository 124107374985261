import * as React from 'react'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

import { STICKY_MAIN_IMAGES } from './sticky-main.images'

const StyledHeading = styled(Typography)({
  color: '#d5bda2',
  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '0px',
  '@media screen and (min-width:1025px)': {
    fontSize: '7em'
  }
})

const StyledTypography = styled(Typography)({
  color: 'rgb(186, 186, 186)',
  '@media screen and (max-width:900px)': {
    textAlign: 'center'
  }
})

const StyledButton = styled(Button)({
  borderRadius: 0,
  color: '#D5BDA2',
  backgroundColor: '#3C352E',
  border: '1px solid #695743',
  padding: ['40px', '20px' ],
  '&:hover, &:active, &:focus': {
    color: '#27221e',
    backgroundColor: '#d5bda2'

  }
})

const StyledBox = styled(Box)({
  backgroundColor: '#27221e',
  backgroundImage: 'assets/home.jpeg',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  width: '100%',
  top: '0px',
  zIndex: 1
})

export default function StickyMain() {

  const [image, setImage] = React.useState(STICKY_MAIN_IMAGES[0])

  function hoverImg(item: string) {
    return () => {
      const img = new Image()
      img.onload = () => {
        // when it finishes loading, update the component state
        setImage(item)
      }
      img.src = item

    }
  }

  return (
    <StyledBox
    >
      <Stack
        height={'100%'}
        direction={{
          xs: 'column',
          md: 'row'
        }}>
        <Stack
          alignItems={'center'}
          height={'100%'}
          px={3}
          pb={{
            xs: 10,
            md: 30
          }}
          pt={{
            xs: 10,
            md: 30
          }}
          width={{
            xs: '100%',
            md: '40%'
          }}
        >
          <Stack
            alignItems={{
              xs: 'center'
            }}>
            <StyledHeading
              level={'h2'}
              textAlign={'center'}
            >
              THE ART OF FLOORING
            </StyledHeading>
            <StyledTypography
              mb={4}
              textAlign={'center'}>
              Alwood Flooring offers professional installation and repair services to keep your floors looking their best.
              From seamless installations to expert repairs, we ensure quality craftsmanship every step of the way.
              <br/>
              Trust us to handle your flooring needs with precision and care.
            </StyledTypography>
            <StyledButton

              // @ts-ignore
              component={Link}
              href={'/contact'}
            >
                CONTACT US
            </StyledButton>
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          sx={{
            transition: 'all .4s ease',
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
          width={{
            xs: '100%',
            md: '60%'
          }}
        >
          {STICKY_MAIN_IMAGES.map((item) => (
            <Stack
              key={item}
              height={'100%'}
              onMouseOver={hoverImg(item)}
              width={`calc(100% / ${STICKY_MAIN_IMAGES.length})`}
              sx={{
                position: 'relative',
                borderRight: '1px solid rgba(255,255,255,.3)',
                transition: 'all .4s ease',
                '&:hover': {
                  cursor: 'pointer',
                  '&::before': {
                    position: 'absolute',
                    content: '""',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    zIndex: 1,
                    background: 'rgba(0, 0, 0, .1)'
                  }

                }
              }}
            >

            </Stack>
          ))}
        </Stack>
      </Stack>
    </StyledBox>
  )
}
