import slide1 from 'assets/home1.jpg'
import slide3 from 'assets/home3.jpeg'
import slide5 from 'assets/home5.jpeg'

export const STICKY_MAIN_IMAGES = [
  slide1,
  'https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/construction-in-a-renovated-room-2023-11-27-05-27-01-utc.jpg',
  slide3,
  'https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/a756ed86-36a0-485d-9cec-8729c30d8352-2023-11-27-04-52-12-utc.jpg',
  slide5
]
