import img1 from 'assets/gallery/img1.jpg'
import img2 from 'assets/gallery/img2.jpg'
import img3 from 'assets/gallery/img3.jpg'
import img4 from 'assets/gallery/img4.jpg'
import img5 from 'assets/gallery/img5.jpg'
import img6 from 'assets/gallery/img6.jpg'
import img7 from 'assets/gallery/img7.jpg'

export const GALLERY_ITEMS = [
  { id: '1', image: img1 },
  { id: '2', image: img2 },
  { id: '3', image: img3 },
  { id: '4', image: img4 },
  { id: '5', image: img5 },
  { id: '6', image: img6 },
  { id: '7', image: img7 }
]
