import React from 'react'

import AboutUs from 'components/about-us'
import Banner from 'components/banner'
import HowWeWork from 'components/how-we-work'
import WhyChooseUs from 'components/why-choose-us'

export default function AboutPage() {

  return (
    <div id={'about'}>
      <Banner
        title={'ABOUT US'}
        text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper'
                + ' mattis, pulvinar dapibus leo.'}
      />
      <AboutUs />
      <WhyChooseUs />
      <HowWeWork />
    </div>
  )
}
