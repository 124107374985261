import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import CssBaseline from '@mui/joy/CssBaseline'
import RootPage from 'pages'
import rootRouter from 'routes'

import ThemeProvider from 'modules/theme'

import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage/>,
    children: rootRouter
  }
])

root.render(
  <ThemeProvider>
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
