import React from 'react'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

const StyledImage = styled('img')({
  width: '100%',
  height: '100%'
})

const StyledHeading = styled(Typography)({
  color: '#d5bda2',
  fontSize: '22px',
  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '0px'
})

const HiddenText = styled(Stack)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: 0,
  overflow: 'hidden',
  visibility: 'hidden',
  opacity: 0,
  transition: 'all .5s ease',
  backgroundColor: '#27221ed6'
})

const OnHoverStack = styled(Stack)({
  '&:hover > div': {
    visibility: 'visible',
    height: '100%',
    opacity: 1
  },
  '&:hover > p': {
    visibility: 'visible',
    height: '100%',
    opacity: 1
  }
})

interface QualityFlooringItemProps {
    img: string,
    title: string,
    subtitle: string
}

export default function QualityFlooringItem({ img, title, subtitle }: QualityFlooringItemProps) {

  return (
    <Stack
      alignItems={{ xs: 'center' }}
      gap={3}
      p={2}
      sx={{
        backgroundColor: '#352e26',
        border: '1px solid #695743',
        '& svg': {
          stroke: '#ffffff'
        }
      }}
    >
      <OnHoverStack
        sx={{
          position: 'relative'
        }}>
        <StyledImage src={img} />
        <HiddenText
          alignItems={'center'}
          justifyContent={'center'}>
          <StyledHeading
            level={'h4'}
            mb={2}
            sx={{
              color: '#ffffff'
            }}
          >
            {title}
          </StyledHeading>
          <Typography
            level={'body-sm'}
            px={2}
            textAlign={'center'}
            sx={{
              color: '#fff'
            }}
          >
            {subtitle}
          </Typography>
        </HiddenText>

      </OnHoverStack>

    </Stack>
  )
}
