import React from 'react'
import Container from '@mui/joy/Container'
import Stack from '@mui/joy/Stack'
import { Mail,Phone } from 'lucide-react'

import FacebookIcon from '../assets/facebook.icon'
import InstagramIcon from '../assets/instagram.icon'

import ContactTypeItem from './contact-type-item'

export default function ContactType() {

  const items1 =[{
    id: 1,
    icon: <Phone
      height={65}
      width={65}
    />,
    title: <a href={'tel:+1 416 6979771'}>+1 416 6979771</a>
  }, {
    id: 2,
    icon: <Mail
      height={65}
      width={65} />,
    title: <a href={'mailto:alen@alwoodflooring.ca'}>alen@alwoodflooring.ca</a>
  }]

  const items2 =[{
    id: 1,
    icon: <FacebookIcon
      height={65}
      width={65}/>,
    title: <a
      href={'https://www.facebook.com/people/Alwood-Flooring/61552465298641/'}
      rel={'noreferrer'}
      target={'_blank'}>Alwood Flooring</a>
  }, {
    id: 2,
    icon: <InstagramIcon
      height={65}
      width={65}/>,
    title: <a
      href={'https://www.instagram.com/alwoodflooring'}
      rel={'noreferrer'}
      target={'_blank'}>@alwoodflooring</a>
  }]

  return (
    <Stack
      py={{
        xs: 7,
        md: 15
      }}
      sx={{
        backgroundColor: '#27221e',
        width: '100%',
        zIndex: 1
      }}>

      <Container
        maxWidth={'lg'}
        sx={{
          zIndex: 2
        }}
      >
        <Stack
          gap={3}
          marginBottom={3}
          direction={{
            xs: 'column',
            sm: 'row'
          }}>
          {items1.map((item, idx) => (
            <ContactTypeItem
              key={item.id}
              {...item}
              isHighlighted={idx %2 === 0}
            />
          ))}
        </Stack>
        <Stack
          gap={3}
          direction={{
            xs: 'column',
            sm: 'row'
          }}>
          {items2.map((item, idx) => (
            <ContactTypeItem
              key={item.id}
              {...item}
              isHighlighted={idx %2 !== 0}
            />
          ))}
        </Stack>
      </Container>
    </Stack>
  )
}
