import React from 'react'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

const StyledTitle = styled(Typography)({
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: 1,
  letterSpacing: '1px',
  '@media screen and (min-width:1025px)': {
    fontSize: '50px'
  }
})

interface AboutUsItemProps{
    count: string
    title: string
    isHighlighted: boolean
}

export default function AboutUsItem({ count, title, isHighlighted }: AboutUsItemProps) {

  return (
    <Stack
      alignItems={'center'}
      flex={1}
      p={2.5}
      sx={{
        backgroundColor: isHighlighted ? 'transparent' : '#3c352e',
        border: `1px solid ${isHighlighted ? 'rgba(105, 87, 67, .2)' : '#695743'}`

      }}
    >
      <StyledTitle
        level={'h3'}
        sx={{
          color: isHighlighted? 'rgba(0, 0, 0, .9)': 'rgba(255, 255, 255, .6)'
        }}>
        {count}
      </StyledTitle>
      <Typography
        sx={{
          color: isHighlighted ? '#7a7a7a': 'rgba(255, 255, 255, .6)'
        }}
      >
        {title}
      </Typography>

    </Stack>
  )

}
