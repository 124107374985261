import Container from '@mui/joy/Container'
import Divider from '@mui/joy/Divider'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'
import { Clock, Mail, MapPin, Phone } from 'lucide-react'

import FacebookIcon from '../assets/facebook.icon'
import InstagramIcon from '../assets/instagram.icon'
import Logo from '../logo'

const StyledContainer = styled('div')({
  backgroundColor: '#3C352E',
  color: '#D5BDA2',
  zIndex: 1
})

const StyledHeaderTypography = styled(Typography)({
  color: '#ffffff',
  fontSize: '1.3em',
  fontWeight: 600
})

const StyledSubheaderTypography = styled(Typography)({
  color: '#d5bda2',
  fontSize: '1em',
  fontWeight: 600
})

const StyledTypography = styled(Typography)({
  color: '#bababa'
})

const StyledLink = styled(Link)({
  color: '#D5BDA2',
  '&>svg': {
    fill: '#D5BDA2'
  }
})

export default function Footer() {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

  }

  const date = new Date()
  return (
    <StyledContainer>
      <Container
        maxWidth={'lg'}
        sx={{
          xs: {
            paddingTop: '20px',
            paddingBottom: '20px'
          },
          md: {
            paddingTop: '100px',
            paddingBottom: '100px'
          }
        }}>
        <Stack
          pb={{
            xs: 3,
            lg: 7
          }}
          pt={{
            xs: 3,
            lg: 14
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              lg: 'row'
            }}
            gap={{
              xs: 0,
              lg: 6
            }}
          >
            <Stack
              alignItems={'center'}
              direction={'row'}
              gap={1}
              justifyContent={'center'}
              pb={{
                xs: 3,
                md: 7
              }}
              width={{
                xs: '100%',
                lg: '32%'
              }}
            >
              <Stack
                alignItems={'center'}
                direction={'column'}
                gap={3}
              >
                <Logo />
                <StyledTypography
                  level={'body-sm'}
                  textAlign={'center'}
                >
                  We appreciate your trust in Alwood Flooring and look forward to transforming your space with quality and care.
                </StyledTypography>
                <Stack
                  direction={'row'}
                  gap={3}
                  justifyContent={'center'}
                  width={'100%'}
                >
                  <StyledLink
                    href={'https://www.facebook.com/people/Alwood-Flooring/61552465298641/'}
                    target={'_blank'}>
                    <FacebookIcon />
                  </StyledLink>
                  <StyledLink
                    href={'https://www.instagram.com/alwoodflooring'}
                    target={'_blank'}>
                    <InstagramIcon />
                  </StyledLink>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction={'column'}
              gap={1}
              width={{
                xs: '100%',
                lg: '65%'
              }}>
              <Stack
                alignItems={'center'}
                direction={{
                  xs: 'column',
                  sm: 'row'
                }}
                gap={{
                  xs: 3,
                  sm: 1
                }}
              >
                <Stack
                  alignItems={'center'}
                  direction={'row'}
                  justifyContent={{
                    xs: 'center',
                    sm: 'flex-start'
                  }}
                  width={{
                    xs: '100%',
                    sm: '33%'
                  }}
                >
                  <Stack pr={2}>
                    <Phone
                      height={26}
                      strokeWidth={1}
                      width={26} />
                  </Stack>
                  <Stack>
                    <StyledHeaderTypography
                      textAlign={{
                        xs: 'center',
                        sm: 'left'
                      }}>
                      PHONE
                    </StyledHeaderTypography>
                    <StyledSubheaderTypography
                      textAlign={{
                        xs: 'center',
                        sm: 'left'
                      }}>
                      <Link
                        href={'tel:+4166979771'}
                        sx={{
                          color: 'inherit'
                        }}>
                        +1 416 6979771
                      </Link>
                    </StyledSubheaderTypography>
                  </Stack>
                </Stack>
                <Stack
                  alignItems={'center'}
                  direction={'row'}
                  justifyContent={{
                    xs: 'center',
                    sm: 'flex-start'
                  }}
                  width={{
                    xs: '100%',
                    sm: '33%'
                  }}>
                  <Stack pr={2}>
                    <Mail
                      height={26}
                      strokeWidth={1}
                      width={26}
                    />
                  </Stack>
                  <Stack>
                    <StyledHeaderTypography
                      textAlign={{
                        xs: 'center',
                        sm: 'left'
                      }}>
                      EMAIL
                    </StyledHeaderTypography>
                    <StyledSubheaderTypography
                      textAlign={{
                        xs: 'center',
                        sm: 'left'
                      }}>
                      <Link
                        href={'mailto:alen@alwoodflooring.ca'}
                        sx={{
                          color: 'inherit'
                        }}>
                      alen@alwoodflooring.ca
                      </Link>
                    </StyledSubheaderTypography>
                  </Stack>
                </Stack>
                <Stack
                  alignItems={'center'}
                  direction={'row'}
                  justifyContent={{
                    xs: 'center',
                    sm: 'flex-start'
                  }}
                  width={{
                    xs: '100%',
                    sm: '33%'
                  }}>
                  <Stack pr={2}>
                    <MapPin
                      height={26}
                      strokeWidth={1}
                      width={26} />
                  </Stack>
                  <Stack>
                    <StyledHeaderTypography
                      textAlign={{
                        xs: 'center',
                        sm: 'left'
                      }}>
                      ADDRESS
                    </StyledHeaderTypography>
                    <StyledSubheaderTypography
                      textAlign={{
                        xs: 'center',
                        sm: 'left'
                      }}>
                      Mississauga, Ontario
                    </StyledSubheaderTypography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider />
              <Stack
                justifyContent={'space-evenly'}
                direction={{
                  xs: 'column',
                  sm: 'row'
                }}>
                <Stack
                  alignItems={{
                    xs: 'center',
                    sm: 'flex-start'
                  }}
                  width={{
                    xs: '100%',
                    sm: '33%'
                  }}>
                  <StyledSubheaderTypography my={3}>
                    NAVIGATION
                  </StyledSubheaderTypography>
                  <StyledLink onClick={scrollToTop}>
                    <StyledTypography>
                      Home
                    </StyledTypography>
                  </StyledLink>
                  <StyledLink
                    href={'/services'}
                    onClick={scrollToTop}>
                    <StyledTypography>
                      Services
                    </StyledTypography>
                  </StyledLink>
                </Stack>
                <Stack
                  alignItems={{
                    xs: 'center',
                    sm: 'flex-start'
                  }}
                  width={{
                    xs: '100%',
                    sm: '33%'
                  }}>
                  <StyledSubheaderTypography my={3}>
                    QUICK LINKS
                  </StyledSubheaderTypography>
                  <StyledLink
                    href={'/contact'}
                    onClick={scrollToTop}>
                    <StyledTypography>
                      Contact Us
                    </StyledTypography>
                  </StyledLink>
                  <StyledLink
                    href={'/gallery'}
                    onClick={scrollToTop}>
                    <StyledTypography>
                      Gallery
                    </StyledTypography>
                  </StyledLink>
                </Stack>
                <Stack
                  alignItems={{
                    xs: 'center',
                    sm: 'flex-start'
                  }}
                  width={{
                    xs: '100%',
                    sm: '33%'
                  }}>
                  <StyledSubheaderTypography my={3}>
                    WORK HOURS
                  </StyledSubheaderTypography>

                  <Stack
                    direction={'row'}
                    gap={1}
                    mb={1}
                  >
                    <Clock
                      strokeWidth={1}
                    />
                    <StyledTypography>
                      Mon - Fri : 7AM-5PM
                    </StyledTypography>
                  </Stack>
                  <Stack
                    direction={'row'}
                    gap={1}
                    mb={3}
                  >
                    <Clock strokeWidth={1} />
                    <StyledTypography>
                      Saturday 9AM-3PM
                    </StyledTypography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            alignContent={'center'}
            pt={3}>
            <Divider />
            <StyledTypography
              mt={3}
              textAlign={'center'}
            >
              {`© ${date.getFullYear()} alwoodfloring • All Rights Reserved`}
            </StyledTypography>
          </Stack>
        </Stack>
      </Container>
    </StyledContainer>
  )
}
