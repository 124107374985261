import React from 'react'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

const StyledHeading = styled(Typography)({
  color: '#d5bda2',
  fontSize: '3em',
  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '0px',
  '@media screen and (min-width:1025px)': {
    fontSize: '7em'
  }
})

interface BannerProps {
    title: string
    text: string | React.ReactNode
}

export default function Banner({ title, text }: BannerProps) {

  return (
    <Stack
      alignItems={'center'}
      width={'100%'}
      py={{
        xs: 7,
        md: 14
      }}
      sx={{
        position: 'relative',
        backgroundImage: 'url("https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/a756ed86-36a0-485d-9cec-8729c30d8352-2023-11-27-04-52-12-utc.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        '&:before': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          content: '""',
          backgroundColor: '#27221e',
          opacity: 0.85
        }
      }}>
      <Stack
        zIndex={1}
        width={{
          xs: '80%',
          md: '40%'
        }}>
        <StyledHeading
          level={'h1'}
          textAlign={'center'}>
          {title}
        </StyledHeading>
        {typeof text === 'string'
          ? (
            <Typography
              textAlign={'center'}
              sx={{
                color: '#bababa'
              }}>
              {text}
            </Typography>
          )
          : text }
      </Stack>

    </Stack>
  )
}
