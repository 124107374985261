import React from 'react'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

import Banner from 'components/banner'
import ContactType from 'components/contact-type'

const StyledHeading = styled(Typography)((theme) => ({
  color: '#d5bda2',
  lineHeight: 1.3,
  '@media screen and (min-width:1025px)': {
    fontSize: '2em'
  }
}))
export default function ContactPage() {

  return (
    <>
      <Banner
        title={'CONTACT US'}
        text={
          <StyledHeading textAlign={'center'}>
            Get a Free, No-Obligation Estimate Today <br/> Know Exactly What to Expect Before You Begin!
          </StyledHeading>
        }
      />
      <ContactType />
    </>
  )
}
