import React from 'react'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

const StyledHeading = styled(Typography)({
  fontSize: '1.7em',
  fontWeight: 600,
  lineHeight: 1,
  letterSpacing: '1px'
})

const StyledOrder = styled(Typography)({
  position: 'absolute',
  top: 6,
  right: 10,
  color: '#ffffff06',
  fontSize: '6em',
  fontWeight: 600,
  lineHeight: '90px'
})

interface HowWeWorkItemProps {
    icon: React.ReactNode,
    title: string,
    text: string | React.ReactNode,
    order: string
}
export default function HowWeWorkItem({ icon, title, text, order }: HowWeWorkItemProps) {

  return (
    <Stack
      alignItems={{ xs: 'center' }}
      px={3}
      gap={{
        xs: 1,
        md: 3
      }}
      py={{
        xs: 3,
        md: 7
      }}
      sx={{
        position: 'relative',
        backgroundColor: '#352e26',
        border: '1px solid #695743',
        '& svg': {
          stroke: '#ffffff'
        }
      }}
      width={{
        xs: '100%',
        md: '25%'
      }}
    >
      <StyledOrder level={'h3'}>
        {order}
      </StyledOrder>
      <Stack
        alignItems={'flex-start'}>
        {icon}
      </Stack>
      <StyledHeading
        level={'h3'}
        sx={{
          color: '#ffffff'
        }}>
        {title}
      </StyledHeading>
      {typeof text === 'string' && (
        <Typography
          level={'body-md'}
          textAlign={'center'}
          sx={{
            color: '#bababa'
          }}
        >
          {text}
        </Typography>
      )}
      {typeof text !== 'string' && text}
    </Stack>
  )
}
