import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

import { OurTestimonialsItem } from './our-testimonials-item'
import OurTestimonialsItemClient from './our-testimonials-item-client'

const StyledOutBorder = styled(Stack)({
  position: 'absolute',
  borderLeft: '2px solid rgb(122, 122, 122)',
  borderBottom: '2px solid rgb(122, 122, 122)',
  width: '90%',
  height: '90%',
  bottom: -20,
  left: -20
})

export default function OurTestimonialsItemLeft({ items }: {items: OurTestimonialsItem[]}) {

  return (
    <Stack
      p={{
        xs: 1,
        md: 5
      }}
      sx={{
        position: 'relative',
        backgroundImage: 'url("https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/a756ed86-36a0-485d-9cec-8729c30d8352-2023-11-27-04-52-12-utc.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundPosition: 'center center',
        '&:before': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          content: '""',
          backgroundColor: '#27221e',
          opacity: 0.85
        }
      }}
      width={{
        xs: '100%',
        md: '50%'
      }}>

      <StyledOutBorder
        display={{
          xs: 'none',
          md: 'flex'
        }} />
      <Stack zIndex={2}>
        {items.map(({ id, client, text }) => (
          <Stack
            key={id}
            gap={{
              xs: 3,
              md: 5
            }}
            p={{
              xs: 1,
              md: 4
            }}
          >
            <Typography sx={{ color: '#bababa' }}>
              {text}
            </Typography>
            <OurTestimonialsItemClient
              {...client}
              isHighlighted/>
          </Stack>
        ))}
      </Stack>

    </Stack>
  )
}
