import React from 'react'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

const StyledHeading = styled(Typography)({
  fontSize: '1.7em',
  fontWeight: 600,
  lineHeight: 1,
  letterSpacing: '1px'
})

const StyledTypography = styled(Typography)({
  '@media screen and (max-width:599px)': {
    textAlign: 'center'
  }
})

interface WhyChooseUsItemProps {
    icon: React.ReactNode,
    title: string,
    text: string,
    highlight: boolean
}

export default function WhyChooseUsItem({ icon, title, text, highlight }: WhyChooseUsItemProps) {

  return (
    <Stack
      p={2.5}
      direction={{
        xs: 'column',
        sm: 'row'
      }}
      sx={{
        backgroundColor: highlight ? '#ede0d4': '#3c352e',
        border: '1px solid #695743',
        '& svg': {
          stroke: highlight ? '#695743' : '#d5bda2'
        }
      }}
    >
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        mb={{ xs: 3, sm: 0 }}
        mr={3}
      >
        {icon}
      </Stack>
      <Stack
        alignItems={{
          xs: 'center',
          sm: 'flex-start'
        }}>
        <StyledHeading
          level={'h3'}
          sx={{
            color: highlight ? '#27221e': '#ffffff'
          }}>
          {title}
        </StyledHeading>
        <StyledTypography
          level={'body-md'}
          sx={{
            color: highlight ? '#7a7a7a': '#bababa'
          }}
        >
          {text}
        </StyledTypography>
      </Stack>
    </Stack>
  )

}
