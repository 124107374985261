import React from 'react'
import CssBaseline from '@mui/joy/CssBaseline'
import { CssVarsProvider } from '@mui/joy/styles'

import baseTheme from './theme.base'

export default function ThemeProvider({ children }: React.PropsWithChildren) {

  return (
    <CssVarsProvider
      defaultMode={'light'}
      theme={baseTheme}>
      <>
        <CssBaseline />
        {children}
      </>
    </CssVarsProvider>
  )
}
